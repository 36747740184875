var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"warehouseinfo","title":_vm.$t('Warehouse Info')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Manager') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.userOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.userOptions},on:{"input":function($event){return _vm.setPhone(_vm.model.manager)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":option.avatar,"text":_vm.avatarText(option.name)}})]},proxy:true}],null,true)},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(option.name))]),_c('small',{staticClass:"text-muted"},[_vm._v("chi nhánh 1")])])]}}],null,true),model:{value:(_vm.model.manager),callback:function ($$v) {_vm.$set(_vm.model, "manager", $$v)},expression:"model.manager"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Phone') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Phone')},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Province/City') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.provinceOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.provinceOptions,"state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.changeDistrict(_vm.model.province)}},model:{value:(_vm.model.province),callback:function ($$v) {_vm.$set(_vm.model, "province", $$v)},expression:"model.province"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('District') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.districtOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.districtOptions,"state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.changeWards(_vm.model.district)}},model:{value:(_vm.model.district),callback:function ($$v) {_vm.$set(_vm.model, "district", $$v)},expression:"model.district"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Wards') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Wards","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.wardsOptions)?_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.wardsOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.wards),callback:function ($$v) {_vm.$set(_vm.model, "wards", $$v)},expression:"model.wards"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Address') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Address')},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"status","title":_vm.$t('Status')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Position')+":","label-cols-md":"6"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":_vm.$t('Position')},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Display on/off')+":","label-cols-md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }